import { createSlice } from "@reduxjs/toolkit";

export const campaignOptionsSlice = createSlice({
  name: "campaignOptions",
  initialState: {
    value: {},
    activeCampaignID: null,
  },
  reducers: {
    setCampaignOptions: (state, action) => {
      let campaignOptions = [];
      for (const label of action.payload.campaignLabels) {
        let campaignObj = { label: label, value: null };
        campaignOptions.push(campaignObj);
      }

      for (const [index, id] of action.payload.campaignIDs.entries()) {
        campaignOptions[index].value = id;
      }

      state.value = campaignOptions;
      state.activeCampaignID = action.payload.activeCampaignID;
    },
  },
});

export const { setCampaignOptions } = campaignOptionsSlice.actions;
