import { createApi } from "@reduxjs/toolkit/query/react";
import {
  GetDuplicateGroupsResponse,
  GetDuplicateDonorResponse,
  CreateDuplicateGroupVariables,
  UpdateDuplicateGroupVariables,
  UpdateDuplicateDonorVariables,
  CreateDuplicateDonorVariables,
  DuplicateDonor,
  DuplicateGroup,
} from "../types/graphql";

const graphqlBaseQuery =
  ({ baseUrl }: { baseUrl: string }) =>
  // not ideal to set getState type as any but doing this to make this machine work for now.
  async ({ body }: { body: string }, { getState }: { getState: any }) => {
    try {
      const token = getState().jwt.value;
      const headers: HeadersInit = {
        "Content-Type": "application/json",
      };

      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }

      const result = await fetch(baseUrl, {
        method: "POST",
        headers,
        body,
      });

      const json = await result.json();

      if (result.ok) {
        return { data: json.data };
      } else {
        return {
          error: {
            status: result.status,
            message: json.errors ? json.errors[0]?.message : "Unknown error",
            data: json.errors,
          },
        };
      }
    } catch (err) {
      let errorMessage = "Network error";
      if (err instanceof Error) {
        errorMessage = err.message;
      }
      return {
        error: {
          status: "FETCH_ERROR",
          message: errorMessage,
          data: null,
        },
      };
    }
  };

export const graphqlApi = createApi({
  baseQuery: graphqlBaseQuery({
    baseUrl: "https://popular-cowbird-causal.ngrok-free.app/api/graphql",
  }),
  tagTypes: ["DuplicateGroups", "DuplicateDonors"],
  endpoints: (builder) => ({
    getDuplicateGroups: builder.query<GetDuplicateGroupsResponse, void>({
      query: () => ({
        body: JSON.stringify({
          query: `
            query GetDuplicateGroups {
              duplicateGroups {
                id
                name
                primaryDonor {
                  id
                  kpfaId
                }
                duplicateDonors {
                  id
                  kpfaId
                }
              }
            }
          `,
        }),
      }),
      providesTags: ["DuplicateGroups"],
    }),
    createDuplicateGroup: builder.mutation<
      DuplicateGroup,
      CreateDuplicateGroupVariables
    >({
      query: (newGroup) => ({
        body: JSON.stringify({
          query: `
            mutation CreateDuplicateGroup($name: String!) {
              createDuplicateGroup(data: { name: $name }) {
                id
                name
                primaryDonor {
                  id
                }
                duplicateDonors {
                  id
                }
              }
            }
          `,
          variables: newGroup,
        }),
      }),
      invalidatesTags: ["DuplicateDonors", "DuplicateGroups"],
    }),
    updateDuplicateGroup: builder.mutation<
      DuplicateGroup,
      UpdateDuplicateGroupVariables
    >({
      query: ({ id, primaryDonorId }) => ({
        body: JSON.stringify({
          query: `
            mutation UpdateDuplicateGroup($id: ID!, $primaryDonorId: ID) {
              updateDuplicateGroup(
                where: { id: $id },
                data: {
                  primaryDonor: { connect: { id: $primaryDonorId } },
                }
              ) {
                id
                  name
                primaryDonor {
                  id
                }
              }
            }
          `,
          variables: { id, primaryDonorId },
        }),
      }),
      invalidatesTags: ["DuplicateDonors", "DuplicateGroups"],
    }),
    getDuplicateDonor: builder.query<
      GetDuplicateDonorResponse,
      { kpfaId: number }
    >({
      query: ({ kpfaId }) => ({
        body: JSON.stringify({
          query: `
            query GetDuplicateDonor($kpfaId: Int!) {
              duplicateDonors(where: { kpfaId: { equals: $kpfaId } }) {
                id
                kpfaId
                group {
                  id
                  name
                  primaryDonor {
                    kpfaId
                  }
                }
                primaryGroup {
                  id
                }
              }
            }
          `,
          variables: { kpfaId },
        }),
      }),
      providesTags: ["DuplicateDonors"],
    }),

    updateDuplicateDonor: builder.mutation<
      DuplicateDonor,
      UpdateDuplicateDonorVariables
    >({
      query: ({ id, group, primaryGroupId }) => {
        let mutationString;
        const variables: {
          id: string;
          group: string;
          primaryGroupId?: string;
        } = { id, group };

        if (primaryGroupId) {
          mutationString = `
        mutation UpdateDuplicateDonor($id: ID!, $group: ID, $primaryGroupId: ID) {
          updateDuplicateDonor(
            where: { id: $id },
            data: {
              group: { connect: { id: $group } },
              primaryGroup: { connect: { id: $primaryGroupId } }
            }
          ) {
            id
            kpfaId
            group {
              id
            }
            primaryGroup {
              id
            }
          }
        }
      `;
          variables.primaryGroupId = primaryGroupId;
        } else {
          mutationString = `
        mutation UpdateDuplicateDonor($id: ID!, $group: ID) {
          updateDuplicateDonor(
            where: { id: $id },
            data: {
              group: { connect: { id: $group } }
            }
          ) {
            id
            kpfaId
            group {
              id
            }
            primaryGroup {
              id
            }
          }
        }
      `;
        }

        return {
          body: JSON.stringify({
            query: mutationString,
            variables,
          }),
        };
      },
      invalidatesTags: ["DuplicateDonors", "DuplicateGroups"],
    }),

    createDuplicateDonor: builder.mutation<
      DuplicateDonor,
      CreateDuplicateDonorVariables
    >({
      query: ({ kpfaId, group }) => ({
        body: JSON.stringify({
          query: `
            mutation CreateDuplicateDonor($kpfaId: Int!, $group: ID) {
              createDuplicateDonor(data: { kpfaId: $kpfaId, group: { connect: { id: $group } } }) {
                id
                kpfaId
                group {
                  id
                }
                primaryGroup {
                  id
                }
              }
            }
          `,
          variables: { kpfaId, group },
        }),
      }),
      invalidatesTags: ["DuplicateDonors", "DuplicateGroups"],
    }),
  }),
});

export const {
  useGetDuplicateGroupsQuery,
  useCreateDuplicateGroupMutation,
  useGetDuplicateDonorQuery,
  useUpdateDuplicateDonorMutation,
  useCreateDuplicateDonorMutation,
  useUpdateDuplicateGroupMutation,
} = graphqlApi;
