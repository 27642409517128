import React, { useState, useEffect } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import "./scss/style.scss";
import moment from "moment";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHome,
  faUsers,
  faChartPie,
  faPrint,
  faEnvelope,
  faShoppingCart,
  faMailBulk,
  faBars,
  faUser,
  faLock,
  faGift,
  faInfoCircle,
  faPlus,
  faFileCsv,
  faTimes,
  faPencilAlt,
  faTrash,
  faDownload,
  faBroadcastTower,
  faFlask,
  faSignOutAlt,
  faRedo,
  faTrashAlt,
  faArrowDown,
  faArrowUp,
  faPhone,
  faHeadphones,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import { GoogleOAuthProvider } from "@react-oauth/google";

library.add(
  faClipboardList,
  faHome,
  faUsers,
  faChartPie,
  faPrint,
  faEnvelope,
  faShoppingCart,
  faMailBulk,
  faBars,
  faUser,
  faLock,
  faGift,
  faInfoCircle,
  faPlus,
  faFileCsv,
  faTimes,
  faPencilAlt,
  faTrash,
  faDownload,
  faBroadcastTower,
  faFlask,
  faSignOutAlt,
  faRedo,
  faTrashAlt,
  faArrowDown,
  faArrowUp,
  faPhone,
  faHeadphones
);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));

const devAuth =
  process.env.NODE_ENV !== "production" &&
  !window.location.href.includes("testing.kpfa.org")
    ? true
    : false;

const App = () => {
  const [authorized, setAuthorized] = useState(devAuth);

  let tokenExpirationTimeout = null;

  useEffect(() => {
    return () => {
      // Anything in here is fired on component unmount.
      clearTimeout(tokenExpirationTimeout);
    };
    //eslint-disable-next-line
  }, []);

  const setTimeoutForTokenExpiration = (expiration) => {
    const now = moment().unix();
    const difference = (expiration - now) * 1000 - 50000;

    tokenExpirationTimeout = setTimeout(() => {
      setAuthorized(false);
    }, difference);
  };

  const handleLoginClick = (expiration) => {
    setAuthorized(true);
    setTimeoutForTokenExpiration(expiration);
  };

  const handleInvalidToken = () => {
    setAuthorized(false);
    localStorage.setItem("jwt", "");
  };

  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <GoogleOAuthProvider clientId="504354494297-1j7146t9hb10i9a1spaho3l8qr0pmqh8.apps.googleusercontent.com">
          <Routes>
            <Route
              exact
              path="/login"
              name="Login Page"
              handleLoginClick={handleLoginClick}
              render={(props) => <Login {...props} />}
            />
            <Route
              path="*"
              name="Home"
              element={
                authorized ? (
                  <DefaultLayout handleInvalidToken={handleInvalidToken} />
                ) : (
                  <Login handleLoginClick={handleLoginClick} />
                )
              }
            />
          </Routes>
        </GoogleOAuthProvider>
      </React.Suspense>
    </HashRouter>
  );
};

export default App;
