import { createSlice } from "@reduxjs/toolkit";

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    visible: false,
  },
  reducers: {
    toggleSidebar: (state, action) => {
      if (action.payload?.visible) {
        state.visible = action.payload.visible;
      } else {
      state.visible = !state.visible;
      }
    },
  },
});

export const { toggleSidebar } = sidebarSlice.actions;
