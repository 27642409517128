import { createSlice } from "@reduxjs/toolkit";

export const jwtSlice = createSlice({
  name: "jwt",
  initialState: {
    value: localStorage.getItem("jwt"),
  },
  reducers: {
    setJwt: (state, action) => {
      state.value = action.payload.token;
    },
    getJwt: (state) => {
      return state.jwt;
    },
  },
});

export const { setJwt, getJwt } = jwtSlice.actions;
