import "react-app-polyfill/stable";
import "core-js";
import { createRoot } from "react-dom/client";
import App from "./App";
//import * as serviceWorker from './serviceWorker'
import { Provider } from "react-redux";
import { store } from "./store";

if (process.env.NODE_ENV !== "development") {
  const LogRocket = require("logrocket");
  LogRocket.init("zmctql/station-admin");
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister()
