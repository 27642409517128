import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define the Icecast stream type
type IcecastStream = {
  listeners: number;
};

// Define the API response structure
type IcecastResponse = {
  icestats: {
    source: IcecastStream[];
  };
};

// Define the base query without authentication handling
const baseQuery = fetchBaseQuery({
  baseUrl: "https://streams.kpfa.org",
});

// Define a service using a base URL and expected endpoints
export const icecast = createApi({
  reducerPath: "icecast",
  baseQuery,
  endpoints: (builder) => ({
    getTotalListeners: builder.query<number, void>({
      query: () => "status-json.xsl",
      transformResponse: (response: IcecastResponse) => {
        // Calculate the total number of listeners
        return response.icestats.source.reduce(
          (total, stream) => total + stream.listeners,
          0,
        );
      },
    }),
  }),
});

// Export hooks for usage in functional components
export const { useGetTotalListenersQuery } = icecast;
