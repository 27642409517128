import { configureStore } from "@reduxjs/toolkit";
import { kpfa } from "./api/kpfa";
import { stripeWorker } from "./api/stripeWorker";
import { testModeSlice } from "./slices/testModeSlice";
import { campaignOptionsSlice } from "./slices/campaignOptionsSlice";
import { sidebarSlice } from "./slices/sidebarSlice";
import { jwtSlice } from "./slices/jwtSlice";
import { userSlice } from "./slices/userSlice";
import { graphqlApi } from "./services/graphql";
import { icecast } from "./api/icecast";

export const store = configureStore({
  reducer: {
    [kpfa.reducerPath]: kpfa.reducer,
    [stripeWorker.reducerPath]: stripeWorker.reducer,
    testMode: testModeSlice.reducer,
    campaignOptions: campaignOptionsSlice.reducer,
    sidebar: sidebarSlice.reducer,
    jwt: jwtSlice.reducer,
    user: userSlice.reducer,
    [graphqlApi.reducerPath]: graphqlApi.reducer,
    [icecast.reducerPath]: icecast.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(kpfa.middleware)
      .concat(stripeWorker.middleware)
      .concat(graphqlApi.middleware)
      .concat(icecast.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
