import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

let initialAccess;
let initialEmail;

if (localStorage.getItem("jwt")) {
  initialAccess = jwtDecode(localStorage.getItem("jwt")).data.access_level;
  initialEmail = jwtDecode(localStorage.getItem("jwt")).data.email;
}

export const userSlice = createSlice({
  name: "user",
  initialState: {
    accessLevel: initialAccess,
    email: initialEmail,
  },
  reducers: {
    setUser: (state, action) => {
      state.accessLevel = action.payload.access_level;
      state.email = action.payload.email;
    },
    getUser: (state) => {
      return state.user;
    },
  },
});

export const { setUser, getUser } = userSlice.actions;
