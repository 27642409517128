import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { RootState } from "../store";

// Define a type for the expected response
type ActiveSubscribersResponse = {
  totalActiveSubscribers: number;
  totalMonthlyAmount: number;
};

// Create a base query function that injects the authorization header
const baseQuery = fetchBaseQuery({
  baseUrl: "https://stripe.kpfa.workers.dev",
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).jwt.value;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

// Define the API service
export const stripeWorker = createApi({
  reducerPath: "stripeWorker",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getActiveSubscribers: builder.query<ActiveSubscribersResponse, void>({
      query: () => "active-subscribers",
    }),
  }),
});

// Export hooks for usage in functional components
export const { useGetActiveSubscribersQuery } = stripeWorker;
